import React from 'react'
import { Flex, Text } from 'rebass'
import styled from 'styled-components'
import footerBg from '../../assets/images/footer-bg.png'
import telegramIcon from '../../assets/images/icon-telegram.png'
import twitterIcon from '../../assets/images/icon-twitter.png'
import { ExternalLink } from 'theme'
import { Container } from 'components/Header'


const FooterFrame = styled.div`
  width: 100%;
  background-image: ${() => `url(${footerBg})`};
  background-repeat: no-repeat;
  background-size: cover;
  padding: 64px;
  
  ${({ theme }) => theme.mediaWidth.upToSmall`
    background: none;
    padding: 32px;
  `};
`

const Divider = styled.div`
  background: #9B99F0;
  width: 100%;
  height: 1px;
  margin: 48px 0px;
`

const SocialLink = styled(ExternalLink)`
    margin: 0px 8px;

    & img {
      width: 40px;
    }
`


export default function Footer() {

  return (
    <FooterFrame>

      <Container>

        <Flex textAlign={'center'} justifyContent={'center'}>
          <SocialLink href="https://twitter.com/hokkaiduinu" target="_blank">
            <img src={twitterIcon} alt="Twitter" />
          </SocialLink>
          <SocialLink href="https://t.me/HokkFinance" target="_blank">
            <img src={telegramIcon} alt="Telegram" />
          </SocialLink>
        </Flex>

        <Divider />

        <Flex justifyContent={'center'}>
          <Text>@ Copyright 2021</Text>
        </Flex>

      </Container>

    </FooterFrame>
  )
}
