import { JSBI, Percent } from '@uniswap/sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { fortmatic, injected, portis, walletconnect, walletlink } from '../connectors'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
export const USDC_ADDRESS = process.env.REACT_APP_USDC_ADDRESS ?? ""
export const AUSDC_ADDRESS = process.env.REACT_APP_AUSDC_ADDRESS ?? ""
export const AWETH_ADDRESS = process.env.REACT_APP_AWETH_ADDRESS ?? ""

export const USDC_DECIMALS = 6
export const WETH_DECIMALS = 18

export const HOKKNFT_IMAGE = 'https://hokk.mypinata.cloud/ipfs/QmZtXvyaKWfJjv9GFaSFquqox7kRqi3wSgcCz82NfZtxYj/'

// Block time here is slightly higher (~1s) than average in order to avoid ongoing proposals past the displayed time
export const AVERAGE_BLOCK_TIME_IN_SECS = 13
export const PROPOSAL_LENGTH_IN_BLOCKS = 40_320
export const PROPOSAL_LENGTH_IN_SECS = AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5'
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true
  },
  FORTMATIC: {
    connector: fortmatic,
    name: 'Fortmatic',
    iconName: 'fortmaticIcon.png',
    description: 'Login using Fortmatic hosted wallet',
    href: null,
    color: '#6748FF',
    mobile: true
  },
  Portis: {
    connector: portis,
    name: 'Portis',
    iconName: 'portisIcon.png',
    description: 'Login using Portis hosted wallet',
    href: null,
    color: '#4A6C9B',
    mobile: true
  }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 400
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

export interface NftType {
  name: string
  image: string
  tokenId: string
  url: string
}



export interface FaqType {
  name: string
  description: string
}

export const faqs: Array<FaqType> = [
  {
    name: "What is HOKK Premium?",
    description: "HOKK Premium is a DeFi aggregator enabling you to stake your crypo assets (we are beginning with ETH and ERC20 USDC) to earn yield in those respective assets. HOKK NFT’s have their own dedicated yield pool funded by the funds from the mint."
  },
  {
    name: "How does HOKK Premium work?",
    description: "Using HOKK Premium, you can place capital (ETH) into a smart contract, choose preset lockup periods (3/6/9/12 months) and be issued with estimations of their monthly yield. The smart contract will send the crypto to aggregator contracts like YFI, where it will be lent out for continuous yield. This yield will then be periodically claimed.<br/>\
    <br/>\
    You are able to use $ETH, $USDC and HOKK NFTs with HOKK Premium and track your contributions in the HOKK Premium portal premium.hokkfi.com HOKK Premium gives you the options to view your initial ETH contribution, the current APY, your percentage of the total pool, and current yield. <br/>\
    <br/>\
    You are not able to withdraw prior to the end of your lock up period, however, you are able to claim your yield prior to when the lock up period ends (you just can’t withdrawal until that lockup period ends). The yield is based on the asset you deposited, so ETH = ETH Yield and USDC = USDC Yield.<br/>\
    <br/>\
    The current Fee structure for HOKK Premium is 0.25% on Deposits & Withdrawals."
  },
  {
    name: "What is a DeFi aggregator?",
    description: "Is a platform that collects information from various DeFi protocols into one place, saving time and increasing efficiency, while  allowing users to make better investment decisions. In the case of HOKK Premium the protocols are decentralized lending platforms."
  },
  {
    name: " If I miss the unlock day does contract roll on? If not, is there any risk in not claiming immediately?",
    description: "The contract continues to generate yield for your deposit even after the unlock day."
  },
  {
    name: " Do I have to stake my NFT to earn yield on HOKK Premium?",
    description: "The NFT’s are unique in that they do not require to be staked themselves. Instead, we created a HOKK NFT dedicated yield pool of USDC (majority) and ETH and it’s those assets that are staked and earning yield. We simply build a mechanism where those eligible to claim that yield are the NFT holders."
  },
  {
    name: " What is the link between HOKK Premium and the $HOKK token?",
    description: "Currently $HOKK is not able to be staked on HOKK Premium as it is based on Yield protocols like AAVE, Compound, YFI, etc so the assets eligible for deposits are based on their assets allowed. Also, good to keep in mind the business that these protocols are in, which is Lending & Borrowing so stability is key (volatility is not ideal for lending), hence stable coins tend to give highest yields. With the volatility that HOKK experiences now, this naturally makes it difficult for these Yield providers to support HOKK.<br/>\
    <br/>\
    At first the two assets represent different use cases and will not share a link. However, the royalties of HOKK NFT’s or the tax revenues of HOKK Token can be used to fund related activities, for example buy back and burn from portions of the NFT volume, or Yield Pool addition/growth coming from the HOKK token tax. The relationship between different parts of the HOKK ecosystem will become apparent as utility in continually implemented."
  }
];


export interface PeriodType {
  name: string
  value: number
}

export const depositPeriods = [
  {
    name: "3 months",
    value: 3
  },
  {
    name: "6 months",
    value: 6
  },
  {
    name: "9 months",
    value: 9
  },
  {
    name: "12 months",
    value: 12
  },
];