import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import React, { useCallback, useMemo, useState } from 'react'
import { Flex, Text } from 'rebass'
import { RadialChart } from 'react-vis';
import { parseUnits } from 'ethers/lib/utils';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { BigNumber } from 'ethers';

import { useActiveWeb3React } from 'hooks';
import { useWalletModalToggle } from 'state/application/hooks';
import { useAaveAPY, /*useCurrentAPY*/ useUserData } from 'hooks/useContract';
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { useIsTransactionPending, useTransactionAdder } from 'state/transactions/hooks';
import { calculateGasMargin, getAPYText, getPremiumContract, tryParseEth, tryParseUsdc } from 'utils';
import { BigText, SmallText, TYPE } from 'theme';
import { ButtonExplore } from 'components/Button'
import { Input as NumericalInput } from 'components/NumericalInput';

import clip4Img from '../../assets/images/clip4.png'
import depositBg from '../../assets/images/deposit-bg.png'
import ethIcon from '../../assets/images/eth-coin.png'
import usdcIcon from '../../assets/images/usdc-coin.png'
import { ChevronDown, ChevronUp } from 'react-feather';
import { depositPeriods, USDC_ADDRESS, USDC_DECIMALS } from 'constants/index';
import { useWindowSize } from 'hooks/useWindowSize';
import { HOKK_PREMIUM_ADDRESS } from 'constants/abis/hokkpremium';
import { Token } from '@uniswap/sdk';
import { useToken } from 'hooks/Tokens';
import { useETHBalances, useTokenBalance } from 'state/wallet/hooks';
import { TokenAmount } from '@uniswap/sdk';
import { useUserAsset } from 'state/user/hooks';


const Wrapper = styled.div`
  position: relative;
  margin: 0px 24px;
  padding: 64px;
  border-radius: 24px;
  background-image: ${() => `url(${depositBg})`};
  background-size: cover;
  background-position: center;
  background-color: #121128;
  
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 32px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    background: none;
    padding: 0px;
    margin-bottom: 36px;
  `};
`

const FlexLine = styled.div`
  display: flex;
  
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: block;
  `};
`

const TextLine = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    text-align: center;
  `};
`

const DesktopImg = styled.img`
  flex: 1;
  margin-left: 64px;
  max-width: 400px;
  width: 100%;
  display: block;
  object-fit: contain;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`

const MobileImg = styled.img`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  display: none;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: block;
  `};
`

const DepositLine = styled.div`
  display: flex;
  gap: 24px;
  margin: 24px 0px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: center;
  `};
`

const DepositText = styled.div`
  border-radius: 16px;
  background: #110E26;
  padding: 16px 32px;
  flex: 1;
  font-size: 20px;
  font-weight: bold;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 420px;
    width: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 320px;
  `};
`

const LockupWrapper = styled(DepositText)`
    display: flex;
    justify-content: space-between;
    position: relative;
`

const LockupExpand = styled.div`
    background: white;
    height: 28px;
    padding: 0px 12px;
    border-radius: 16px;
    cursor: pointer;
    user-selection: none;
`

const PeriodWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 64px;
  left: 0px;
  z-index: 1000;
  padding: 12px 8px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.17);
  background: rgba(8, 5, 18, 0.8);
  backdrop-filter: blur(2px);
`

const PeriodItem = styled.div`
    font-size: 18px;
    font-weight: light;
    line-height: 42px;
    cursor: pointer;
    padding: 0px 24px;

    &:hover {
      background: #0C0A1BA0;
      border-radius: 16px;
    }
`

const PieWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: block;
  `};
`

const PieChart = styled(RadialChart)`
  flex: 1;
  text-align: center;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin: 0 auto;
  `};
`

const PieText = styled.div`
  position: absolute;
  top: 120px;
  left: 0px;
  width: 300px;
  font-size: 32px;
  text-align: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 80px;
    width: 50%;
    font-size: 24px;
    text-align: center;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
  `};
`

const PieLabels = styled.div`
  flex: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    flex-direction: column;
    align-items: center;
  `};
`

const PieLabel = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0px;
`

const PieCircle = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  background: ${({ color }) => color};
  border-radius: 50%;
  margin-right: 12px;
`

const PieLabelText = styled.div`
`

const InputLine = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 24px;
`

const StyledInput = styled(NumericalInput)`
  width: 320px;
  flex: 1;
  border-radius: 24px;
  padding: 12px 18px;
  background: #362c3160;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 180px;
    padding: 8px 12px;
    margin: 0px;
  `};
`

const CoinsLine = styled.div`
    display: flex;
    gap: 32px;
    justify-content: flex-start;

    ${({ theme }) => theme.mediaWidth.upToMedium`
      justify-content: center;
    `};
`

const ButtonCoin = styled(ButtonExplore)`
    width: 180px;
    justify-content: flex-start;
    padding: 8px 16px;
    gap: 12px;
    opacity: 0.5;

    &.active {
      opacity: 1;
    }

    & img {
      width: 32px;
    }

`

export default function Deposit({ history }: RouteComponentProps) {

  const { chainId, account, library } = useActiveWeb3React();
  const toggleWalletModal = useWalletModalToggle();
  const addTransaction = useTransactionAdder();
  const { userEthDeposit, userUsdcDeposit, userEthYield, userUsdcYield } = useUserData(account);
  const [isEth, setIsEth] = useUserAsset()
  const coinName = isEth ? 'ETH' : 'USDC';
  // const APY = useCurrentAPY(isEth);
  const { ethAPY, usdcAPY } = useAaveAPY();

  const usdcToken: Token | null | undefined = useToken(USDC_ADDRESS, USDC_DECIMALS);
  const usdcApproveAmount = usdcToken ? new TokenAmount(usdcToken, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff") : undefined;
  const [approval, approveCallback] = useApproveCallback(usdcApproveAmount, HOKK_PREMIUM_ADDRESS);
  
  const usdcBalance = useTokenBalance(account ?? undefined, usdcToken ? usdcToken : undefined);
  const ethBalance = useETHBalances(account ? [account] : [])?.[account ?? '']

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [amount, setAmount] = useState<string>('')
  const [periodExpanded, setPeriodExpanded] = useState<boolean>(false)
  const [periodIdx, setPeriodIdx] = useState<number>(0)
  const windowSize = useWindowSize();

  const userDeposit = (isEth ? tryParseEth(userEthDeposit) : tryParseUsdc(userUsdcDeposit));
  const userYield = (isEth ? tryParseEth(userEthYield) : tryParseUsdc(userUsdcYield));

  const data = useMemo(() => {
    return [{
      angle: parseFloat(userDeposit ?? '0'),
      label: 'Eth',
      color: '#1E75FF',
      'radius': 125,
      'radius0': 155
    },
    {
      angle: parseFloat(userYield ?? '0'),
      label: 'Funds',
      color: '#FF974A',
      'radius': 125,
      'radius0': 155
    }]
  }, [
    userDeposit,
    userYield
  ]);

  const isPending = useIsTransactionPending(txHash);

  const handleETH = () => {
    setIsEth(true);
    setAmount(ethBalance ? ethBalance.toSignificant(4) : '0');
  }

  const handleUSDC = () => {
    setIsEth(false);
    setAmount(usdcBalance ? usdcBalance.toSignificant(4) : '0');
  }

  const handleExpand = () => {
    setPeriodExpanded(!periodExpanded);
  }

  const selectPeriod = (idx: number) => {
    setPeriodIdx(idx);
    setPeriodExpanded(false);
  }

  const pieSize = useMemo(() => {
    if (windowSize.width !== undefined && windowSize.width > 768) {
      return 300;
    }
    else {
      return 200;
    }
  }, [
    windowSize
  ]);

  const onUserInput = useCallback((typedValue: string) => {
    setAmount(typedValue);
  }, [])

  async function depositEth() {
    if (!chainId || !library || !account) return
    const contract = getPremiumContract(chainId, library, account)

    let estimate,
      method: (...args: any) => Promise<TransactionResponse>,
      args: Array<string | string[] | number>,
      value: BigNumber | null;

    const lockupTimestamp = 60 * 60 * 24 * 30 * depositPeriods[periodIdx].value;
    const lockupPeriod: number = Math.round((new Date()).getTime() / 1000) + lockupTimestamp;
    console.log(lockupPeriod);

    estimate = contract.estimateGas.depositETH
    method = contract.depositETH
    args = [
      lockupPeriod,
    ]
    value = parseUnits(amount, "ether");

    setAttemptingTxn(true)
    await estimate(...args, value ? { value } : {})
      .then(estimatedGasLimit =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit)
        }).then(response => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary:
              'Deposit Successed.'
          })
          setTxHash(response.hash)
          setErrorMessage('');
        })
      )
      .catch(error => {
        setAttemptingTxn(false)

        console.log(error);

        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          if (error.data) {
            setErrorMessage(error.data.message);
          }
          else if (error.reason) {
            setErrorMessage(error.reason);
          }
          else {
            setErrorMessage(error.message);
          }
        }
        else {
          setErrorMessage('User rejected transaction.');
        }
      })
  }

  async function depositUsdc() {
    if (!chainId || !library || !account) return
    const contract = getPremiumContract(chainId, library, account)

    let estimate,
      method: (...args: any) => Promise<TransactionResponse>,
      args: Array<string | string[] | number>,
      value: BigNumber | null;

    const _amount = parseUnits(amount, USDC_DECIMALS).toString();
    const lockupPeriod: number = Math.round((new Date()).getTime() / 1000) + 60 * 60 * 24 * 30 * depositPeriods[periodIdx].value;

    estimate = contract.estimateGas.depositUSDC
    method = contract.depositUSDC
    args = [
      _amount,
      lockupPeriod,
    ]
    value = null;

    setAttemptingTxn(true)
    await estimate(...args, value ? { value } : {})
      .then(estimatedGasLimit =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit)
        }).then(response => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary:
              'Deposit Successed.'
          })
          setTxHash(response.hash)
          setErrorMessage('');
        })
      )
      .catch(error => {
        setAttemptingTxn(false)

        console.log(error);

        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          if (error.data) {
            setErrorMessage(error.data.message);
          }
          else if (error.reason) {
            setErrorMessage(error.reason);
          }
          else {
            setErrorMessage(error.message);
          }
        }
        else {
          setErrorMessage('User rejected transaction.');
        }
      })
  }


  return (
    <>

      <Wrapper>

        <FlexLine>
          <TextLine>
            <BigText>Deposit</BigText>
            <SmallText>Deposit ETH and choose a lockup Period to start saving and erning</SmallText>
            <MobileImg src={clip4Img} alt="HOKKFi" />

            <CoinsLine>
              <ButtonCoin onClick={handleETH} className={isEth ? 'active' : ''}>
                <img src={ethIcon} alt='ETH' />
                <Text>ETH</Text>
              </ButtonCoin>
              <ButtonCoin onClick={handleUSDC} className={isEth ? '' : 'active'}>
                <img src={usdcIcon} alt='USDC' />
                <Text>USDC</Text>
              </ButtonCoin>
            </CoinsLine>

            {account ?
              <PieWrapper>
                <PieText>{userDeposit} <br /> {coinName}</PieText>
                <PieChart
                  data={data}
                  width={pieSize}
                  height={pieSize}
                  colorType='literal'
                />
                <PieLabels>
                  <PieLabel>
                    <PieCircle color="#1E75FF" />
                    <PieLabelText>{coinName}: {userDeposit ?? '0'}</PieLabelText>
                  </PieLabel>
                  <PieLabel>
                    <PieCircle color="#FF974A" />
                    <PieLabelText>Funds: {userYield ?? '0'}</PieLabelText>
                  </PieLabel>
                </PieLabels>
              </PieWrapper>
              : null
            }

          </TextLine>

          <DesktopImg src={clip4Img} alt="HOKKFi" />

        </FlexLine>


        <DepositLine>
          <LockupWrapper>
            <Text>
              Lockup For: {depositPeriods[periodIdx].name}
            </Text>
            <LockupExpand onClick={handleExpand}>
              {
                periodExpanded
                  ? <ChevronDown color='black' />
                  : <ChevronUp color='black' />
              }
            </LockupExpand>
            {
              periodExpanded ?
                <PeriodWrapper>
                  {
                    depositPeriods.map((period, idx) =>
                      <PeriodItem key={idx} onClick={() => selectPeriod(idx)}>
                        {period.name}
                      </PeriodItem>
                    )
                  }
                </PeriodWrapper>
                : null
            }
          </LockupWrapper>
          <DepositText>
            Expected APY: {getAPYText(isEth ? ethAPY : usdcAPY)}%
          </DepositText>
        </DepositLine>

        {
          account ?
            <InputLine>
              <StyledInput value={amount} onUserInput={onUserInput} />
              {
                isEth ?
                  <ButtonExplore onClick={depositEth} disabled={isPending || attemptingTxn}>I want to deposit</ButtonExplore>
                  : (approval === ApprovalState.APPROVED ?
                    <ButtonExplore onClick={depositUsdc} disabled={isPending || attemptingTxn}>I want to deposit</ButtonExplore>
                    : <ButtonExplore onClick={approveCallback} disabled={approval === ApprovalState.PENDING || isPending || attemptingTxn}>Approve</ButtonExplore>)
              }
            </InputLine>
            : <InputLine>
              <ButtonExplore onClick={toggleWalletModal} disabled={isPending || attemptingTxn}>Connect Wallet</ButtonExplore>
            </InputLine>
        }

        {
          errorMessage ?
            <Flex justifyContent={'center'} marginY={'24px'}>
              <TYPE.error error={true}>
                {errorMessage}
              </TYPE.error>
            </Flex> : null
        }

      </Wrapper>

    </>
  )
}
