import { Contract } from '@ethersproject/contracts'
import { useEffect, useMemo, useState } from 'react'
import { ChainId } from '@uniswap/sdk'
import { BigNumber } from 'ethers'
import { useActiveWeb3React } from './index'
import { useMultipleContractSingleData, useSingleCallResult, useSingleContractMultipleData } from 'state/multicall/hooks'
import { getContract, tryParseEth, tryParseUsdc } from '../utils'
import ERC20_ABI from '../constants/abis/erc20.json'
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json'
import ENS_ABI from '../constants/abis/ens-registrar.json'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall'
import { HOKK_PREMIUM_ABI, HOKK_PREMIUM_ADDRESS } from 'constants/abis/hokkpremium'
import ERC20_INTERFACE, { ERC20_BYTES32_ABI } from 'constants/abis/erc20'
import { HOKK_NFT_ABI, HOKK_NFT_ADDRESS } from 'constants/abis/hokknft'
import axios from 'axios'
import { AUSDC_ADDRESS, AWETH_ADDRESS } from 'constants/index'

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, false)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.GÖRLI:
      case ChainId.ROPSTEN:
      case ChainId.RINKEBY:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
        break
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}


export function usePremiumContract(): Contract | null {
  return useContract(HOKK_PREMIUM_ADDRESS, HOKK_PREMIUM_ABI, false)
}

export function useNFTContract(): Contract | null {
  return useContract(HOKK_NFT_ADDRESS, HOKK_NFT_ABI, false)
}

export function useETHPrice(): number {

  const [ethPrice, setEthPrice] = useState<number>(0)

  useEffect(() => {

    async function fetchEthPrice() {
      const { data } = await axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD');
      if (data.USD) {
        setEthPrice(data.USD);
      }
    }

    fetchEthPrice();

  }, [])

  return ethPrice;
}

export function useAaveAPY(): {
  ethAPY: number | undefined,
  usdcAPY: number | undefined
} {
  const [usdcAPY, setUsdcAPY] = useState<number>()
  const [ethAPY, setEthAPY] = useState<number>()

  useEffect(() => {

    async function fetchAPY() {
      const { data } = await axios.get('https://aave-api-v2.aave.com/data/markets-data');
      if (data.reserves) {
        const reserves: Array<any> = data.reserves;

        const wethRates = reserves.filter(r => r.aTokenAddress.toLowerCase() === AWETH_ADDRESS.toLowerCase()).map(r => r.liquidityRate);
        if (wethRates.length > 0) {
          setEthAPY(wethRates[0]);
        }

        const usdcRates = reserves.filter(r => r.aTokenAddress.toLowerCase() === AUSDC_ADDRESS.toLowerCase()).map(r => r.liquidityRate);
        if (usdcRates.length > 0) {
          setUsdcAPY(usdcRates[0]);
        }
      }
    }

    fetchAPY();

  }, []);

  return {
    ethAPY: ethAPY,
    usdcAPY: usdcAPY
  }

}

export function useCurrentAPY(isEth: boolean): BigNumber {
  const contract = usePremiumContract()
  const method = isEth ? 'getCurrentETHAPY' : 'getCurrentUSDCAPY'
  const amountResult = useSingleCallResult(contract, method)
  return amountResult.result?.[0];
}

export function useUserData(account: string | null | undefined): ({
  userEthDeposit: BigNumber, userUsdcDeposit: BigNumber, userEthLockup: BigNumber, userUsdcLockup: BigNumber, userEthYield: BigNumber, userUsdcYield: BigNumber, userNftIds: Array<string>
}) {

  const contract = usePremiumContract()

  const { result } = useSingleCallResult(contract, 'getUserData', [account ?? undefined]);

  let _userNftIds = result?.[6] ?? [];

  return {
    userEthDeposit: result?.[0] ?? undefined,
    userUsdcDeposit: result?.[1] ?? undefined,
    userEthLockup: result?.[2] ?? undefined,
    userUsdcLockup: result?.[3] ?? undefined,
    userEthYield: result?.[4] ?? undefined,
    userUsdcYield: result?.[5] ?? undefined,
    userNftIds: _userNftIds.slice(0, 5)
  };
}

export function useUserNFTs(account: string | null | undefined): Array<string> {

  const contract = usePremiumContract()

  const { result } = useSingleCallResult(contract, 'getUserNFTIDs', [account ?? undefined]);

  return result?.[0] ?? undefined;
}

export function useNftOwner(tokenId: string | undefined): string | undefined {

  const contract = useNFTContract()

  const { result } = useSingleCallResult(contract, 'ownerOf', [tokenId]);
  return result?.[0] ?? undefined;
}


export function useNftYield(isEth: boolean, tokenId: string | undefined): BigNumber | undefined {

  const contract = usePremiumContract()

  const yieldFunction = isEth ? 'getNFTETHYield' : 'getNFTUSDCYield';
  const { result } = useSingleCallResult(contract, yieldFunction, [tokenId]);
  return result?.[0] ?? undefined;
}

export function useNftYields(isEth: boolean, tokenIds: Array<string>): { [tokenId: string]: BigNumber | undefined } {

  const contract = usePremiumContract()

  const yieldFunction = isEth ? 'getNFTETHYield' : 'getNFTUSDCYield';

  const results = useSingleContractMultipleData(
    contract,
    yieldFunction,
    tokenIds.map(tokenId => [tokenId])
  )

  return useMemo(
    () =>
      tokenIds.reduce<{ [tokenId: string]: BigNumber }>((memo, tokenId, i) => {
        const value = results?.[i]?.result?.[0]
        if (value) memo[tokenId] = value;
        return memo
      }, {}),
    [tokenIds, results]
  )

}

export function useTotalETHDeposit(): BigNumber | undefined {

  const contract = usePremiumContract()

  const result = useSingleCallResult(contract, 'totalETHDeposit', []).result;
  return result?.[0] ?? undefined;
}

export function useTotalUSDCDeposit(): BigNumber | undefined {

  const contract = usePremiumContract()

  const result = useSingleCallResult(contract, 'totalUSDCDeposit', []).result;
  return result?.[0] ?? undefined;
}

export function useLockedAmount(ethAmount: BigNumber | undefined, usdcAmount: BigNumber | undefined, ethPrice: number): number | undefined {

  if (ethAmount && usdcAmount) {
    const ethValue = parseFloat(tryParseEth(ethAmount) ?? '0') * ethPrice;
    const usdcValue = parseFloat(tryParseUsdc(usdcAmount) ?? '0');
    return ethValue + usdcValue;
  }
  else {
    return undefined;
  }

}

export function useYieldAmount(ethAmount: BigNumber | undefined, usdcAmount: BigNumber | undefined, ethPrice: number): number | undefined {

  const balances = useMultipleContractSingleData(
    [
      AWETH_ADDRESS,
      AUSDC_ADDRESS
    ],
    ERC20_INTERFACE,
    'balanceOf',
    [HOKK_PREMIUM_ADDRESS]
  );

  const awethAmount: BigNumber = balances?.[0].result?.[0] ?? BigNumber.from(0);
  const ausdcAmount: BigNumber = balances?.[1].result?.[0] ?? BigNumber.from(0);

  if (ethAmount && usdcAmount) {
    const ethValue = parseFloat(tryParseEth(awethAmount.sub(ethAmount)) ?? '0') * ethPrice;
    const usdcValue = parseFloat(tryParseUsdc(ausdcAmount.sub(usdcAmount)) ?? '0');
    return ethValue + usdcValue;
  }
  else {
    return undefined;
  }

}