import Footer from 'components/Footer'
import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import Header, { Container } from '../components/Header'
import Polling from '../components/Header/Polling'
import URLWarning from '../components/Header/URLWarning'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import Home from './Home'

import effect1Img from '../assets/images/effect-1.png'
import effect2Img from '../assets/images/effect-2.png'
import Deposit from './Deposit'
import Funds from './Funds'
import SearchModal from 'components/SearchModal'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: center;
`

const BodyWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0px 16px;
  `};

  z-index: 1;
`

const Effect1 = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  & img {
    width: 200px;
  }

`
const Effect2 = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  & img {
    width: 600px;
  }
`


export default function App() {
  return (
    <Suspense fallback={null}>
      <Route />
      <AppWrapper>
        <URLWarning />
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        <BodyWrapper>

          <Effect1>
            <img src={effect1Img} alt="Effect 1" />
          </Effect1>

          <Effect2>
            <img src={effect2Img} alt="Effect 2" />
          </Effect2>

          <Popups />
          <Polling />
          <SearchModal />
          <Web3ReactManager>
            <Container>
              <Switch>
                <Route path="/home" component={Home} />
                <Route path="/deposit" component={Deposit} />
                <Route path="/funds" component={Funds} />
                <Route exact path="/">
                  <Redirect to="/home" />
                </Route>
              </Switch>
            </Container>
          </Web3ReactManager>
        </BodyWrapper>
      </AppWrapper>
      <Footer />
    </Suspense>
  )
}
